<template>
  <div id="course">
    <!-- 课程详情 -->
    <div class="banner">
      <h1>{{ course.title }}</h1>
      <div class="pc">
        <p>
          专题：{{ course.venueName && course.venueName.name || '' }}
        </p>
        <p>
          会议地点：{{ course.venueName && course.venueName.address || '' }}
        </p>
        <p>
          案例来源：{{ course.venueName && course.venueName.company || course.lecturer && course.lecturer[0].company || '' }}
        </p>
        <p>
          分享时间：{{ course.venueName && course.venueName.date || '' }}
        </p>
      </div>
      <div class="mob">
        <p>
          专题：{{ course.venueName && course.venueName.name || '' }}
        </p>
        <p>
          案例来源：{{ course.lecturer && course.lecturer[0] && course.lecturer[0].company || '' }}
        </p>
        <p>
          会议地点：{{ course.venueName && course.venueName.address || '' }}
        </p>
        <p>
          分享时间：{{ course.venueName && course.venueName.date || '' }}
        </p>
      </div>
    </div>
    <div class="banner_bottom">
      <div class="con" v-for="(lecturer, lindex) in course.lecturer" :key="'courseLecturer'+lindex">
        <div class="thumb">
          <router-link v-if="lecturer.id" :to="{ path:`/${global.year}/teacher`, name:`Teacher${global.year}`, query:{ id:lecturer.id } }">
            <div class="img">
              <img :src="lecturer.thumbs" alt="">
              <!-- <img :src="global.getSrc(lecturer.thumbs)" alt=""> -->
            </div>
          </router-link>
        </div>
        <div class="text">
          <p class="title">
            <router-link v-if="lecturer.id" :to="{ path:`/${global.year}/teacher`, name:`Teacher${global.year}`, query:{ id:lecturer.id } }">
              {{ lecturer.name }}
            </router-link>
            <span v-if="course.companythumbs && course.companythumbs.includes('http')">
              | <img :src="course.companythumbs" />
              <!-- <span>{{course.lecturer[0].company}}</span> -->
            </span>
          </p>
          <p class="desc" v-html="`${
            lecturer.company&&lecturer.company!=='无' ? lecturer.company+'&nbsp;&nbsp;' : ''
          }${
            lecturer.position&&lecturer.position!=='无' ? lecturer.position : ''
          }`"></p>
          <p class="desc" v-html="lecturer.desc&&lecturer.desc!=='无' ? lecturer.desc : ''"></p>
        </div>
      </div>
    </div>
    <div class="course">
      <div class="con" v-if="!showPPT || showPPT==='ppt'">
        <h2>课程概要</h2>
        <p v-html="course.desc"></p>
        <h2>听众收益</h2>
        <p v-html="course.profit"></p>
        <slot v-if="showPPT==='ppt'">
          <h2>课程PPT</h2>
          <p v-if="!timeLimit">PPT暂未开放</p>
          <p v-else-if="!token || token===''">请登录并购票后查看PPT</p>
          <p v-else-if="!buyTicket">请购票并激活后查看PPT</p>
          <PPT v-else :list="coursePPT" :page="page"></PPT>
        </slot>
      </div>
      <slot v-else-if="showPPT==='menu'">
        <div class="btns">
          <button :class="menuSelected===0?'selected':''" @click="selectMenu(0)">课程信息</button>
          <button :class="menuSelected===1?'selected':''" @click="selectMenu(1)">课程PPT</button>
        </div>
        <div class="con" v-if="menuSelected===0">
          <h2>课程概要</h2>
          <p v-html="course.desc"></p>
          <h2>听众收益</h2>
          <p v-html="course.profit"></p>
        </div>
        <div class="con" v-if="menuSelected===1">
          <h2>课程PPT</h2>
          <p v-if="!timeLimit">PPT暂未开放</p>
          <p v-else-if="!token || token===''">请登录并购票后查看PPT</p>
          <p v-else-if="!buyTicket">请购票并激活后查看PPT</p>
          <PPT v-else :list="coursePPT" :page="page"></PPT>
        </div>
      </slot>
    </div>
    <div class="other">
      <div class="con">
        <h1>相关案例</h1>
        <div class="case_con">
          <!-- <ul>
            div替换为router-link
            <div class="case" v-for="(item,index) in course.related" :key="'course_related'+index"></div>
            <router-link
              class="case"
              v-for="(item, index) in course.related"
              :key="'course_related' + index"
              :to="{
                path: `/${global.year}/course`,
                name: `Course${global.year}`,
                query: { id: item.id },
              }"
            >
              <div class="content">
                <img :src="item.companythumbs?item.companythumbs:require('../../public/img/logo.png')" alt="" />
                <p class="name">
                  <slot
                    v-if="
                      course.lecturer &&
                      course.lecturer[0] &&
                      course.lecturer[0].name
                    "
                  >
                    {{ item.lecturer[0].name }}
                  </slot>
                </p>
                <p class="company">
                  <slot
                    v-if="
                      course.lecturer &&
                      course.lecturer[0] &&
                      course.lecturer[0].company
                    "
                  >
                    {{ item.lecturer[0].company }} </slot
                  ><br />
                  <slot
                    v-if="
                      course.lecturer &&
                      course.lecturer[0] &&
                      course.lecturer[0].position
                    "
                  >
                    {{ item.lecturer[0].position }}
                  </slot>
                </p>
              </div>
              <p class="case-title">
                {{ item.title }}
              </p>
            </router-link>
          </ul> -->
          <SelectedCaseNew :caseList="course.related"></SelectedCaseNew>
        </div>
        <div class="clearboth"></div>
      </div>
    </div>
    <!-- card -->
    <card-index v-if="$route.query.card" :_kind="'course'" :_info="course"></card-index>
  </div>
</template>

<script>
import { sid, getMeetingTime, getScheduleDetail, getMyTicketUniversal, getCoursePPT } from "@/utils/api.js";

export default {
  name: "Course",
  components: {
    SelectedCaseNew:() => import("@/components/SelectedCaseNew"),
    PPT: () => import("@/components/CoursePPT"),
    CardIndex: () => import('../components/Card/Index.vue'),
  },
  data() {
    return {
      isPCSize: false,
      params: "",
      course: "",
      coursePPT: [],
      showPPT: false, // 开放ppt组件，ppt/menu
      buyTicket: false, // 是否已购票
      timeLimit: false, // 是否在PPT开放时间内
      menuSelected: 0,
      page: 0,
      token: "",
      metaInfo: {
        seoTitle: "",
        seoKeyword: "",
        seoDescription: "",
      },
    };
  },
  metaInfo() {
    return {
      title: this.metaInfo.seoTitle,
      meta: [
        {
          name: "keywords",
          content: this.metaInfo.seoKeyword,
        },
        { name: "description", content: this.metaInfo.seoDescription },
      ],
    };
  },
  created() {
    this.params = this.$route.query;
    // this.getCourse();
    this.showPPT = 'ppt'
    // this.showPPT = this.params.more;
    this.token = window.localStorage.getItem("token");
    this.isPCSize = window.innerWidth>767 ? true : false;
    window.addEventListener('resize', () => {
      $(window).off('resize');
      this.isPCSize = window.innerWidth>767 ? true : false;
    })
  },
  mounted() {
    this.getCourse();
    this.getPPT();
  },
  watch: {
    isPCSize(_new, _old) {
      if(_new !== _old) {
        this.menuSelected = 0;
      }
    },
    $route(to, from) {
      //监听路由是否变化
      if (to.query.id != from.query.id) {
        this.params = this.$route.query;
        this.getCourse();
        this.getPPT();
      }
    },
  },
  methods: {
    getCourse: function () {
      this.$http
        .jsonp(getScheduleDetail + sid(this.global.year) + "/" + this.params.id)
        .then((data) => {
          return data.json();
        })
        .then(async (data) => {
          // console.log(data);
          if (data.errno == 0 && data.data) {
            // console.log("course data: ",data.data);
            this.course = data.data;
            
            /* 设置meta */
            if (data.data.seo !== null && !Array.isArray(data.data.seo)) {
              this.metaInfo = data.data.seo;
            }
            if (this.metaInfo.seoTitle === "") {
              this.metaInfo.seoTitle =
                data.data.title + "-  GIAC 全球互联网架构大会";
            }

            /* 课程是否已开始 */
            let startTime = ''
            if (data.data.venueName && data.data.venueName.date && !data.data.venueName.date.includes('1970-')) {
              // 案例分享时间(如：'2024-05-25 14:30-15:30')
              let startDay = data.data.venueName.date.split(' ')[0] || '';
              let startHours = data.data.venueName.date.split(' ')[1] || '';
              startTime = startDay + ' ' + startHours.split('-')[0];
            } else {
              // 大会第一天
              await this.$http.jsonp(getMeetingTime + sid(this.global.year))
                .then(data => {
                  return data.json();
                })
                .then(res => {
                  console.log(res);
                  if (res.status == '200' && res.data && res.data.startTime) {
                    startTime = this.global.formatTime(res.data.startTime,'Y-M-D h:m');
                  }
                })
            }
            let thisTime = this.global.formatTime('','Y-M-D h:m')
            console.log(thisTime, '|', startTime);
            this.timeLimit = thisTime >= startTime ? true : false;

            /* 相关案例logo */
            for (let r = 0; r < data.data.related.length; r++) {
              this.$http
                .jsonp(
                  getScheduleDetail +
                    sid(this.global.year) +
                    "/" +
                    this.params.id +
                    data.data.related[r].id
                )
                .then((res) => {
                  return res.json();
                })
                .then((res) => {
                  if (res.errno == 0) {
                    this.course.related[r].companythumbs =
                      res.data.companythumbs;
                    // console.log("logo: ",this.course);
                  }
                });
            }
            // console.log("cours: ",this.course);
          }
        });
    },
    selectMenu: function (menu) {
      // if(menu != 1) {
      //   this.page = 0;
      // }
      // if(menu==1 && (!this.token || this.token==='')) {
      //   // window.alert('请先登录');
      //   // this.$message('请先登录');
      //   this.$toast('请先登录');
      // } else {
      //   this.menuSelected = menu;
      // }
      this.menuSelected = menu;
    },
    getPPT: function () {
      this.$http
        .jsonp(`${getCoursePPT}?sid=${sid(this.global.year)}&cid=${this.params.id}&token=${this.token}&choose=1`)
        // .jsonp(`${getCoursePPT}?sid=${2898}&cid=${15807}&token=${this.token}&choose=1`)
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          // console.log('ppt ', data);
          if(data.errno == '0' && data.data){
            this.buyTicket = (data.data.isBuyTicket && data.data.isBuyTicket!=='false') ? true : false;
            this.coursePPT = data.data.attachment && data.data.attachment.imgs || [];
          }
        })
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-size: 14px;
  text-align: left;
  margin: 0;
  padding: 0;
}
a,
a:hover,
a:visited {
  color: #337ab7;
  text-decoration: none;
}
// div
h1,
h2,
h3,
h4,
h5,
h6,
p,
a,
span,
b,
i,
em,
div {
  font-family: BlinkMacSystemFont, -apple-system, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Helvetica, Arial,
    sans-serif;
}

#course {
  // margin-top: 135px;
  text-align: left;
  .banner {
    // margin-top: 135px;
    width: 100%;
    height: 305px;
    background: #fafbfc;
    text-align: center;
    h1 {
      width: 100%;
      text-align: center;
      font-size: 36px;
      font-family: PingFang SC Bold;
      font-weight: bold;
      color: #003177;
      padding-top: 80px;
      padding-bottom: 40px;
    }
    div {
      max-width: 725px;
      margin: 0 auto;
      p {
        width: 50%;
        float: left;
        font-size: 18px;
        font-family: PingFang SC Regular;
        font-weight: 400;
        color: #666666;
        line-height: 22px;
        padding-top: 12px;
      }
    }
  }
  .banner_bottom {
    width: 100%;
    // height: 328px;
    padding-top: 52px;
    padding-bottom: 65px;
    .con {
      max-width: 1110px;
      margin: 0 auto;
      padding-top: 43px;
      display: flex;
      flex-wrap: nowrap;
      .thumb {
        margin-right: 60px;
      }
      .img {
        width: 193px;
        height: 211px;
        // float: left;
        border-radius: 7px;
        background-size: 100% 100% !important;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: top;
          border-radius: 7px;
        }
      }
      .text {
        padding: 20px 0;
        .title,
        .title * {
          font-size: 34px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #212121;
          line-height: 22px;
          // span {
          //   font-weight: bold;
          //   color: #de1b28;
          // }
          img {
            height: 34px;
            line-height: 34px;
            margin-left: 3px;
            // margin-top: -5px;
          }
        }
        .desc {
          font-size: 16px;
          font-family: PingFang SC Regular;
          font-weight: 400;
          color: #999999;
          line-height: 22px;
        }
        .desc:nth-of-type(2) {
          padding: 22px 0;
          margin-top: 5px;
        }
        .desc:nth-of-type(3) {
          line-height: 30px;
        }
      }
    }
  }
  .course {
    width: 100%;
    padding-top: 66px;
    padding-bottom: 100px;
    background: #fafbfc;
    .btns {
      max-width: 1142px;
      margin: 0 auto;
      margin-bottom: 25px;
      display: flex;
      justify-content: flex-start;
      --border-color: #dadbdc; // #0b70a4
      --text-color: #babbbc; // #003177
      button {
        height: 40px;
        line-height: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 25px;
        margin-right: 10px;
        font-size: 18px;
        color: var(--text-color);
        border-radius: 20px;
        border: 1px solid var(--border-color);
        background-color: #ffffff;
        outline: none;
        position: relative;
        z-index: 5;
        overflow: hidden;
        &.selected {
          // color: #ffffff;
          color: #999;
          &::after{
            content: '';
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 1;
            opacity: 0.45;
            background-color: var(--border-color);
            // background-color: rgba(29, 161, 253, 0.2);// #003177;
          }
          // border-color: #0b70a4;
        }
      }
    }
    .con {
      max-width: 1142px;
      margin: 0 auto;
      background: #ffffff;
      border-left: 8px solid #003177;
      padding: 34px 66px 50px 50px;
      h2 {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #131313;
        line-height: 29px;
        padding-top: 43px;
        padding-bottom: 32px;
        &:nth-of-type(1) {
          padding-top: 0;
        }
      }
      p {
        // white-space: pre-line;
        font-size: 16px;
        font-family: PingFang SC Regular;
        font-weight: 400;
        color: #999999;
        line-height: 30px;
      }
    }
  }
  .other {
    width: 100%;
    background: #ffffff;
    padding-top: 86px;
    .con {
      // max-width: 1225px;
      // max-width: 1250px;
      margin: 0 auto;
      h1 {
        width: 100%;
        text-align: center;
        font-size: 36px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #003177;
        padding-bottom: 50.5px;
      }
      .case_con {
        width: 100%;
        // max-width: 1225px;
        margin: 0 auto;
        ul {
          display: flex;
          flex-wrap: wrap;
          padding-bottom: 43px;
          margin-bottom: 0;
          a:hover {
            box-shadow: 0px 0px 8px 0px rgba(72, 75, 77, 0.1);
          }
          a {
            display: flex;
            flex-direction: column-reverse;
            padding: 22px 22px 21px 20px;
            margin: 12.5px;
            text-decoration: none;
            text-align: left;
            background: #ffffff;
            border: 1px solid #d6dce3;
            .case-title {
              width: 305px;
              padding-left: 7px;
              padding-bottom: 10px;
              line-height: 25px;
              font-weight: 500;
              font-size: 18px;
              font-family: PingFang SC;
              color: #003177;
            }
            .content {
              border-top: 1px solid #d6dce3;
              padding-top: 36px;
              padding-left: 9px;
              width: 340px;
              img {
                width: 69px;
                height: 13px;
              }
              .name {
                font-size: 18px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #484848;
                margin-bottom: 6px;
              }
              .company {
                font-size: 14px;
                font-family: PingFang SC, PingFang SC-Regular;
                color: #3e3a39;
                line-height: 16px;
              }
            }
          }
          a:nth-child(3n) {
            margin-right: 0;
          }
        }
      }
    }
  }
  // .pc {
  //   display: block;
  // }
  // .mob {
  //   display: none;
  // }
  // @media (max-width: 1023px) {
  //   #course {
  //     // margin-top: 86px!important;
  //     // .banner {
  //     //   margin-top: 86px!important;
  //     // }
  //   }
  // }
  // @media (max-width: 750px) {
  @media (max-width: 767px) {
    // .pc {
    //   display: none;
    // }
    // .mob {
    //   display: block;
    // }
    .banner {
      height: auto !important;
      padding: 45px 10% !important;
      // margin-top: 10px;
      h1 {
        font-size: 18px !important;
        line-height: 26px !important;
        padding: 0 !important;
        padding-bottom: 20px !important;
      }
      div {
        p {
          width: 100% !important;
          float: none !important;
          text-align: center;
          font-size: 14px !important;
          line-height: 16px !important;
          padding: 6px 0 !important;
        }
      }
    }
    .banner_bottom {
      height: auto !important;
      padding-top: 65px !important;
      padding-bottom: 45px !important;
      .con {
        width: 86%;
        padding-top: 0 !important;
        display: block;
        .thumb {
          margin-right: 0;
        }
        .img {
          width: 32vw !important;
          height: 34vw !important;
          max-width: 135px !important;
          max-height: 145px !important;
          float: none !important;
          margin: 0 !important;
          margin: 0 auto !important;
        }
        .text {
          padding-top: 25px !important;
          .title,
          .title * {
            font-size: 15px !important;
            font-weight: bold !important;
          }
          .title {
            line-height: 22px !important;
            text-align: center;
            img {
              height: 16px !important;
            }
          }
          .desc {
            font-size: 14px !important;
            font-weight: 500 !important;
            line-height: 24px !important;
            color: #4c4c4c !important;
          }
          .desc:nth-of-type(2) {
            padding-top: 5px !important;
            padding-bottom: 16px !important;
            text-align: center !important;
            color: #293f76 !important;
            font-size: 13px !important;
            font-family: PingFang SC medium !important;
            font-weight: 500 !important;
            margin: 0 !important;
          }
        }
      }
    }
    .course {
      padding: 40px 0 !important;
      .btns {
        width: 90%;
        margin-bottom: 15px;
        button {
          height: 32px;
          padding: 0 20px;
          // margin-right: 5px;
          font-size: 15px;
        }
      }
      .con {
        width: 90% !important;
        padding: 35px 20px !important;
        padding-bottom: 45px !important;
        h2 {
          font-size: 15px !important;
          padding-bottom: 20px !important;
        }
        p {
          font-size: 14px !important;
        }
      }
    }
    .other {
      padding-top: 45px !important;
      .con {
        h1 {
          padding-bottom: 24px !important;
          font-size: 18px !important;
        }
        .case_con {
          * {
            font-size: 15px !important;
          }
          ul {
            a {
              width: 90%;
              padding: 13px !important;
              .content {
                width: 100% !important;
                padding-top: 18px !important;
                img {
                  width: 60px !important;
                  height: 12px !important;
                }
                p {
                  font-size: 13px !important;
                }
              }
            }
          }
        }
      }
    }
  }
}

.clearboth {
  clear: both;
  float: none !important;
  width: 0 !important;
  height: 0 !important;
  border: none !important;
  margin: 0 !important;
  padding: 0 !important;
}
</style>